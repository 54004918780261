<template>
    <div>
        <div class="main-box">
            <div class="main">
                <div class="tabs-nav">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="全部" name="first">
                        </el-tab-pane>
                        <el-tab-pane label="未接单" name="second">
                        </el-tab-pane>
                        <el-tab-pane label="进行中" name="ww">
                        </el-tab-pane>
                        <el-tab-pane label="已完结" name="ee">
                        </el-tab-pane>
                    </el-tabs>
                    <div class="search-l">
                        <input type="text" placeholder="搜索订单/订单号" v-model="value">
                        <div>搜索</div>
                    </div>
                </div>
                <!-- 全部 -->
                <div v-show="current==0">
                    <div class="cellter">
                        <div class="itema">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    需求类别<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="itemb">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    发布需求日期<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="itemc">需求名称</div>
                        <div class="itemd">预算金额</div>
                        <div class="iteme">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    需求状态<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="item171">订单号</div>
                    </div>
                    <div class="cell-box" v-for="item in 5" :key="item">
                        <div class="cell">
                            <div class="itema">知识产权</div>
                            <div class="itemb">2021-12-01</div>
                            <div class="itemc">知识产权|xxxx公司软件著作权申请</div>
                            <div class="itemd">10000.00</div>
                            <div class="iteme red">未接单</div>
                            <div class="item171">152345656</div>
                        </div>
                    </div>
                </div>
                <!-- 未接单 -->
                <div v-show="current==1">
                    <div class="cellter">
                        <div class="itema">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    需求类别<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="itemb">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    发布需求日期<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="itemc">需求名称</div>
                        <div class="itemd">预算金额</div>
                        <div class="iteme">订单号</div>
                        <div class="item171">操作</div>
                    </div>
                    <div class="cell-box" v-for="item in 5" :key="item">
                        <div class="cell">
                            <div class="itema">知识产权</div>
                            <div class="itemb">2021-12-01</div>
                            <div class="itemc">知识产权|xxxx公司软件著作权申请</div>
                            <div class="itemd">10000.00</div>
                            <div class="iteme">152345656</div>
                            <div class="item171 blue">
                                <span @click="dialogVisible=true">查看进度</span>
                                <div>放弃接单</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 进行中 -->
                <div v-show="current==2">
                    <div class="cellter">
                        <div class="itema">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    需求类别<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="itemb">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    发布需求日期<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="itemc">需求名称</div>
                        <div class="itemd">预算金额</div>
                        <div class="itemb">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    进行状态<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="iteme">订单号</div>
                        <div class="item190">操作</div>
                    </div>
                    <div class="cell-box" v-for="item in 5" :key="item">
                        <div class="cell">
                            <div class="itema">知识产权</div>
                            <div class="itemb">2021-12-01</div>
                            <div class="itemc">知识产权|xxxx公司软件著作权申请</div>
                            <div class="itemd">10000.00</div>
                            <div class="itemb">接单中标</div>
                            <div class="iteme">152345656</div>
                            <div class="item190 blue">
                                <div @click="show = !show">服务方列表信息 <i class="el-icon-arrow-down"></i></div>
                                <span>查看进度</span>
                            </div>
                        </div>
                        <div class="cell-item" v-show="show">
                            <div class="cell-list">
                                <div class="hui" style="width:78px">接单日期</div>
                                <div class="hui" style="width:150px">服务方名称</div>
                                <div class="hui" style="width:260px">我接受</div>
                                <div class="hui" style="width:65px">联系电话</div>
                                <div class="hui" style="width:120px">操作</div>
                            </div>
                            <div class="cell-list" v-for="item in 5" :key="item">
                                <div style="width:78px">2021-11-01</div>
                                <div style="width:150px">深圳市xx有限公司</div>
                                <el-popover placement="bottom" title="" width="400" trigger="click" content="接受描述接受描述接受描述接受描述........">
                                    <div slot="reference" style="width:260px">接受描述接受描述接受描述接受描述......</div>
                                </el-popover>
                                <div style="width:65px" class="blue">点击获取</div>
                                <div style="width:120px"><span class="blue" style="margin-right:20px">中标</span><span class="red">淘汰</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 已完结 -->
                <div v-show="current==3">
                    <div class="cellter">
                        <div class="itema">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    需求类别<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="itemb">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    发布需求日期<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="itemc">需求名称</div>
                        <div class="itemd">预算金额</div>
                        <div class="itemb">
                            <el-dropdown trigger="click" placement="bottom">
                                <span class="el-dropdown-link">
                                    完结状态<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>基础设置</el-dropdown-item>
                                    <el-dropdown-item>我的订单</el-dropdown-item>
                                    <el-dropdown-item>我的钱包</el-dropdown-item>
                                    <el-dropdown-item>我的店铺</el-dropdown-item>
                                    <el-dropdown-item>我的创作中心</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="iteme">订单号</div>
                        <div class="item190">操作</div>
                    </div>
                    <div class="cell-box" v-for="item in 5" :key="item">
                        <div class="cell">
                            <div class="itema">知识产权</div>
                            <div class="itemb">2021-12-01</div>
                            <div class="itemc">知识产权|xxxx公司软件著作权申请</div>
                            <div class="itemd">10000.00</div>
                            <div class="itemb">接单中标</div>
                            <div class="iteme">152345656</div>
                            <div class="item190 blue">
                                <div @click="show = !show">服务方列表信息 <i class="el-icon-arrow-down"></i></div>
                                <span>查看进度</span>
                            </div>
                        </div>
                        <div class="cell-item" v-show="show">
                            <div class="cell-list">
                                <div class="hui" style="width:78px">接单日期</div>
                                <div class="hui" style="width:150px">服务方名称</div>
                                <div class="hui" style="width:260px">我接受</div>
                                <div class="hui" style="width:65px">联系电话</div>
                                <div class="hui" style="width:120px">操作</div>
                            </div>
                            <div class="cell-list" v-for="item in 5" :key="item">
                                <div style="width:78px">2021-11-01</div>
                                <div style="width:150px">深圳市xx有限公司</div>
                                <el-popover placement="bottom" title="" width="400" trigger="click" content="接受描述接受描述接受描述接受描述........">
                                    <div slot="reference" style="width:260px">接受描述接受描述接受描述接受描述......</div>
                                </el-popover>
                                <div style="width:65px" class="blue">点击获取</div>
                                <div style="width:120px"><span class="blue" style="margin-right:20px">中标</span><span class="red">淘汰</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <Release :dialogVisible="dialogVisible" @close="close" />
    </div>
</template>

<script>
import Release from "../../components/serve/release.vue";

export default {
    components: { Release },
    name: "QppOrder",
    data() {
        return {
            activeName: "first",
            current: 0,
            value: "",
            show:false,
            dialogVisible: false,//接单进度弹出层
        };
    },
    mounted() {
    },
    methods: {
        handleClick(tab) {
            this.current = tab.index;
        },
        close() {
            this.dialogVisible = false
        }
    },
};
</script>

<style lang="scss" scoped>
.main-box {
    background: #f6f6f6;
    padding: 20px 0 40px;
}
.main {
    width: 1140px;
    padding: 30px;
    background: #fff;
    margin: 0 auto;
}
.tabs-nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e4e7ed;
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
    width: 100px; // width: 80px !important;
}
/deep/.el-tabs__item {
    font-size: 22px;
    color: #666666;
    width: 100px;
    height: 50px;
    font-weight: bold;
    text-align: center;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
/deep/.el-tabs__header {
    margin: 0;
}
.search-l {
    border: 1px solid #999999;
    display: flex;
    color: #fff;
    height: 40px;
    margin-right: 20px;
    font-size: 14px;
    input {
        flex: 1;
        color: #222;
        border: none;
        outline: none;
        height: 100%;
        padding: 0 20px;
    }
    div {
        padding: 10px 30px;
        background: #999999;
        cursor: pointer;
    }
}
.cellter {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #222222;
    font-weight: bold;
    text-align: center;
    /deep/.el-dropdown {
        font-size: 16px;
        font-weight: bold;
        color: #222;
    }
}
.itema {
    width: 85px;
    text-align: center;
}
.itemb {
    width: 118px;
    text-align: center;
}
.itemc {
    width: 132px;
    text-align: center;
}
.itemd {
    width: 75px;
    text-align: center;
}
.iteme {
    width: 85px;
    text-align: center;
}
.itemf {
    width: 90px;
    text-align: center;
}
.item171 {
    width: 171px;
    text-align: center;
    display: flex;
    justify-content: center;
    span {
        cursor: pointer;
        margin-right: 40px;
    }
    div {
        cursor: pointer;
    }
}
.item190 {
    width: 210px;
    text-align: center;
    display: flex;
    justify-content: center;
    span {
        cursor: pointer;
    }
    div {
        display: flex;
        margin-right: 10px;
        cursor: pointer;
    }
}
.red {
    color: #e40012;
}
.blue {
    color: #2ea7e0;
}
.pink {
    color: #fb487c;
}
.cell-box {
    padding: 30px 0 15px;
    border-bottom: 1px solid #eeeeee;
}
.cell {
    padding-bottom: 15px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    color: #666666;
    text-align: center;
}
.cell-item {
    padding: 20px;
    background: #f6f6f6;
    .cell-list {
        display: flex;
        margin-bottom: 14px;
        justify-content: space-between;
        color: #666;
        font-size: 14px;
        text-align: center;
    }
}
.hui {
    color: #999999;
}
</style>