<template>
    <div>
        <div class="search">
            <div class="enter">
                <div class="category">
                    <el-dropdown trigger="click" placement="bottom">
                        <span class="el-dropdown-link">
                            类别<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>基础设置</el-dropdown-item>
                            <el-dropdown-item>我的订单</el-dropdown-item>
                            <el-dropdown-item>我的钱包</el-dropdown-item>
                            <el-dropdown-item>我的店铺</el-dropdown-item>
                            <el-dropdown-item>我的创作中心</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <input type="text" placeholder="请输入关键字搜索" v-model="keyWord" class="input">
                <button class="enter-btn">搜索</button>
            </div>
        </div>
        <div class="head">
            <div class="nav">
                <div :class="index==curten?'active':'bor'" v-for="(item,index) in navList" :key="item.id" @click="setCur(item,index)">
                    <span v-if="!item.child">
                        {{item.title}}
                    </span>
                    <el-dropdown trigger="hover" placement="bottom" @command="childChange" v-else>
                        <span>
                            {{item.title}}<el-badge v-if="item.title=='我的店铺'" :is-dot="dot.shop" class="item"></el-badge>
                            <el-badge v-if="item.title=='我的订单'" :is-dot="dot.user" class="item"></el-badge> <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="val" v-for="val in item.child" :key="val.id">{{val.title}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                </div>
            </div>
        </div>

        <!-- 我的店铺 -->
        <!-- <div v-if="curten == 3"> -->
        <router-view v-if="token" />
        <div v-else class="login">
            请先<span><span @click="goPersonalLogin">登录</span>/<span @click="goLogin">注册</span></span>后再来看
        </div>
        <!-- </div> -->
        <Complete @close="close" :show="show" />
        <Footer />
    </div>
</template>

<script>
import Footer from '../../components/footer.vue';
import EnterOrder from './enterOrder.vue';
import SetInfo from './setInfo.vue';
import PersonOrder from './personOrder.vue';
import Wallet from './wallet.vue';
import Complete from '../../components/complete.vue';
export default {
    components: { Footer, SetInfo, EnterOrder, PersonOrder, Wallet, Complete },
    data() {
        return {
            keyWord: "",
            navList: [
                { id: 0, title: '基础设置', path: '/setInfo' },
                {
                    id: 1, title: '我的订单',
                    path: '/personOrder',
                    child: [
                        { id: 0, title: '我的发布', path: '/personOrder' },
                        { id: 1, title: '我的接单', path: '/myOrder' },
                        { id: 2, title: '我的购买服务', path: '/myBuy' },
                    ]
                },
                { id: 2, title: '我的钱包', path: '/wallet' },
                {
                    id: 3, title: '我的店铺',
                    path: '/myShop',
                    child: [
                        { id: 0, title: '店铺管理', path: '/myShop' },
                        { id: 1, title: '订单详情', path: '/orderDetail' },
                        { id: 2, title: '信息编辑', path: '/editInfo' },
                    ]
                },
                {
                    id: 4, title: '我的创作中心',
                    path: '/myCreation',
                    child: [
                        { id: 0, title: '我的基础资料', path: '/myCreation' },
                        { id: 1, title: '发帖详情', path: '/invitation' },
                    ]
                },
            ],
            curten: 1, //当前下标
            token: false, //是否存在token
            show: false, //完善信息弹出层
            shop: null, //店铺信息
            info: {}, //我的信息
            // index: ""
            dot: ""
            /** */

        };
    },

    mounted() {

        if (localStorage.getItem('token')) {
            this.token = true
        } else {
            this.token = false
        }

        this.getInfo()
        this.getShop()
        this.init()
    },
    methods: {
        init() {
            // /center/myOrder/bigRed
            // sessionStorage.getItem('aaa');
            // console.log("this.$a_user", this.$a)
            // if (this.$a != 0) {
            //     this.curten = this.$a
            // }
            this.$util.post('/center/myOrder/bigRed').then(res => {
                console.log(res);
                this.dot = res.data
            })
        },
        getInfo() {
            this.$util.post('/user/info').then(res => {
                this.info = res.data
            })
        },
        // 获取店铺信息
        getShop() {
            this.$util.post('/shop/myShop').then(res => {
                this.shop = res.data
            })
        },
        // 父级点击跳转判断
        /**
         * 
         * @param {sessionStorage.getItem("token,payPsd")} 是否存在token并且设置支付密码 
         *
         */
        setCur(item, index) {
            console.log(item);

            // 当前菜单栏数组下标不为0时判断是否存在   不存在弹出完善资料弹出层 存在正常走
            if (index != 0) {
                if (localStorage.getItem("token") && sessionStorage.getItem('payPsd') == 'true') {
                    this.curten = index
                    sessionStorage.setItem('cur', index)
                    this.$router.push({ path: item.path })
                } else if (sessionStorage.getItem('payPsd') == 'false') {
                    this.show = true

                }
            } else {
                this.curten = index
                sessionStorage.setItem('cur', index)
                this.$router.push({ path: item.path })
            }
            // 如果下标为3(我的店铺) 进行多个值判断  --- 是有拥有店铺   没有店铺跳转到入驻页面进行店铺入驻
            // 如果之前有入驻过店铺 但是没有完成店铺的所有入驻步骤  则直接跳转到当前的进度
            /**
             * @param state  --state==4&&shop.noReal(是否填写实名验证)就是已经完成店铺的入驻 5是失败 其余就是正在惊醒时
             *  -------- 这边的state传参可以忽略掉  入驻那边已经进行进口请求回去
             */
            if (index == 3 && sessionStorage.getItem('payPsd') == 'true') {
                if (this.info.shopId == null) {
                    alert('你当前还没有店铺')
                    this.$router.push({ path: '/settled' })

                } else if (this.shop.state == 4 && !this.shop.noReal) {
                    this.$router.push({ path: item.path })
                } else if (this.shop && this.info.accountType == 1) {
                    alert('你当前还没有店铺')

                    if (this.shop.type == '1') {
                        this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
                    } else {
                        this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
                    }


                } else if (this.shop && this.info.accountType == 2) {
                    alert('你当前还没有店铺')
                    if (this.shop.type == '1') {
                        this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
                    } else {
                        this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
                    }

                } else {
                    this.$router.push({ path: '/settled' })
                }
            }
        },
        // 子类点击跳转  --同父级一样

        childChange(item) {
            if (sessionStorage.getItem("token") && sessionStorage.getItem('payPsd') == 'true') {
                this.$router.push({ path: item.path })
            } else if (sessionStorage.getItem('payPsd') == 'false') {
                return this.show = true

            }

            if (this.info.shopId == null) {
                if (item.title == '店铺管理' || item.title == '订单详情' || item.title == '信息编辑') {
                    alert('你当前还没有店铺')
                    this.$router.push({ path: '/settled' })
                }
            } else if (this.shop.state == 4 && !this.shop.noReal) {
                this.$router.push({ path: item.path })
            } else if (this.shop && this.info.accountType == 1) {
                if (item.title == '店铺管理' || item.title == '订单详情' || item.title == '信息编辑') {

                    if (this.shop.type == '1') {
                        this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
                    } else {
                        this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
                    }
                }

            } else if (this.shop && this.info.accountType == 2) {
                if (item.title == '店铺管理' || item.title == '订单详情' || item.title == '信息编辑') {
                    if (this.shop.type == '1') {
                        this.$router.push({ path: '/personShop', query: { active: this.shop.state } })
                    } else {
                        this.$router.push({ path: '/enterShop', query: { active: this.shop.state } })
                    }
                }
            } else {
                this.$router.push({ path: '/settled' })
            }
        },
        // 关闭弹框
        close() {
            this.show = false
        },
        //去登陆
        goPersonalLogin() {
            this.$router.push({ path: '/personalLogin' })
        },
        // 去注册
        goLogin() {
            this.$router.push({ path: '/login' })
        }
    },
    watch: {

    }
};
</script>

<style lang="scss" scoped>
.head {
    background: #515151;
    .nav {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        color: #fff;

        .bor {
            padding: 12px 53px;
            border-right: 1px solid #707070;
            display: flex;
            cursor: pointer;
            align-items: center;
            // padding-right: 53px;
            font-size: 14px;
            justify-content: center;
            /deep/.el-dropdown {
                color: #fff;
            }
        }
        .bor:hover {
            padding: 12px 53px;
            border-right: 1px solid #707070;

            justify-content: center;
            display: flex;
            background: rgba(255, 255, 255, 0.4);
            cursor: pointer;
            align-items: center;
            // padding-right: 53px;
            font-size: 14px;
        }
        .active {
            padding: 12px 53px;
            border-right: 1px solid #707070;

            justify-content: center;
            display: flex;
            background: rgba(255, 255, 255, 0.4);
            cursor: pointer;
            align-items: center;
            // padding-right: 53px;
            font-size: 14px;
            /deep/.el-dropdown {
                color: #fff;
            }
        }
    }
}
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    margin: 0 auto 30px;
    .enter {
        width: 820px;
        height: 50px;
        display: flex;
        align-items: center;
        border: 1px solid #e40012;
        .category {
            width: 100px;
            // height: 100%;
            text-align: center;
            border-right: 1px solid #c4c4c4;
        }
        .input {
            flex: 1;
            padding: 0 30px;
            height: 36px;
            border: none;
            outline: none;
        }
        .enter-btn {
            width: 150px;
            height: 100%;
            background: #e40012;
            border: none;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
        }
    }
    .release {
        margin-left: 30px;
        width: 150px;
        height: 50px;
        border: 1px solid #f1f1f1;
        color: #e40012;
        cursor: pointer;
        background: #fff;
        font-size: 16px;
    }
}
.login {
    width: 1200px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    span {
        cursor: pointer;
        color: #2ea7e0;
    }
    background: #f6f6f6;
    margin: 30px auto;
}
</style>